import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const faviconSrc =
  process.env.NODE_ENV === 'production'
    ? '/favicon.png'
    : '/favicon.dev.png';

function SEO({
  description,
  lang = 'en',
  meta,
  title = 'CSS for JavaScript Developers',
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription =
    description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={null}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          href: `${faviconSrc}?v=1`,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: 'https://css-for-js.dev/og-image-v2.jpg',
        },
        {
          property: `og:image:alt`,
          content:
            'CSS for JavaScript Developers, an interactive online course that gives you CSS superpowers.',
        },
        {
          property: `og:image:width`,
          content: 1280,
        },
        {
          property: `og:image:height`,
          content: 675,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: 'twitter:image',
          content: 'https://css-for-js.dev/og-image-v2.jpg',
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {},
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
