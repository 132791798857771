import styled from "styled-components";

export default styled.div`
  min-width: ${(props) => props.$size}px;
  min-height: ${(props) => props.$size}px;

  @media (max-width: 700px) {
    min-width: ${(props) =>
      typeof props.$mobileSize === "number"
        ? `${props.$mobileSize}px`
        : undefined};
    min-height: ${(props) =>
      typeof props.$mobileSize === "number"
        ? `${props.$mobileSize}px`
        : undefined};
  }
`;
